import React, { useRef, useState } from 'react';
import Link from 'next/link';
import Config from '@config/index';
import {
  StackedCarousel,
  ResponsiveContainer
} from 'react-stacked-center-carousel';
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from 'swiper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { numberWithSufix } from '@hooks/functions';
import { ProfilePictureComponent } from '@components/shared-components/profile-picture/profile-picture.component';
import globalStyles from '../../styles/global.js';

SwiperCore.use([EffectCoverflow, Navigation, Autoplay]);

export const CarouselComponent = ({ videos }: any) => {
  const ref = useRef<any>();
  const [currentVideoDetail, setCurrentVideoDetail] = useState<any>(null);
  const videoHtml: any = [];

  if (videos && videos.length > 0) {
    videos.forEach((video: any, index: number) => {
      videoHtml.push(
        <video
          id="video-player"
          key={index}
          width="100%"
          height="100%"
          src={video.id ? `${Config.BASE_URL}/video/${video.id}` : ''}
          muted
          autoPlay
          disablePictureInPicture
          controlsList="nodownload"
        />
      );
    });
  }

  const Slide = ({ data, isCenterSlide, dataIndex }: any) => {
    if (!data[dataIndex]) return <div></div>;
    const { thumbnail, id } = data[dataIndex];

    if (isCenterSlide) {
      setCurrentVideoDetail(data[dataIndex]);
    }

    const placeholder = '/assets/images/stardeos02.png';
    const image = thumbnail?.includes('https') ? thumbnail : placeholder;

    return (
      <>
        <div
          style={{
            width: '100%',
            height: 300,
            userSelect: 'none',
            borderRadius: '4px',
            overflow: 'hidden'
          }}
          className="slide-container"
        >
          <Link href={`/video/${id}`}>
            <a>
              {isCenterSlide ? (
                <>
                  {currentVideoDetail?.title && (
                    <div className="semi-overlay">
                      {/* <h4 className="title-vid">{currentVideoDetail.title}</h4> */}
                      <div className="secondary-text-vid">
                        <p className="text-vid-left">
                          <ProfilePictureComponent
                            img={currentVideoDetail.creator && currentVideoDetail.creator.avatar}
                            initials={currentVideoDetail.creator && currentVideoDetail.creator.username}
                            size={25}
                          />
                          {currentVideoDetail.creator.username}{" - "}
                          {currentVideoDetail.title.length>35? currentVideoDetail.title.slice(0,33)+"...":currentVideoDetail.title}
                        </p>
                        <div className="stack-vid-icon">
                          <p className="text-vid-right">
                            {numberWithSufix(currentVideoDetail.views)}
                          </p>
                          <svg
                            className="carousel-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 105c-101.8 0-188.4 62.4-224 151 35.6 88.6 122.2 151 224 151s188.4-62.4 224-151c-35.6-88.6-122.2-151-224-151zm0 251.7c-56 0-101.8-45.3-101.8-100.7S200 155.3 256 155.3 357.8 200.6 357.8 256 312 356.7 256 356.7zm0-161.1c-33.6 0-61.1 27.2-61.1 60.4s27.5 60.4 61.1 60.4 61.1-27.2 61.1-60.4-27.5-60.4-61.1-60.4z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                  <img
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                      borderRadius: 8
                    }}
                    draggable={false}
                    src={image}
                    alt="image"
                    onError={(e: any) => {
                      e.target.src = placeholder;
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                      borderRadius: 8
                    }}
                    draggable={false}
                    src={image}
                    alt="image"
                    onError={(e: any) => {
                      e.target.src = placeholder;
                    }}
                  />
                </>
              )}
            </a>
          </Link>
        </div>
        <style jsx>{`
          .slide-container {
            position: relative;
          }
          .semi-overlay {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 100%;
            // background: rgb(18, 18, 18);
            // background: linear-gradient(
            //   0deg,
            //   rgba(18, 18, 18, 0.75) 44%,
            //   rgba(255, 255, 255, 0) 95%
            // );
            // height: 70%;
            background: #191D32;
            height: 75px;
            bottom: 0;
            padding: 25px;
            justify-content: flex-end;

            h4 {
              font-family: var(--primary-font);

              @media screen and (max-width: 600px) {
                font-size: 16px;
              }
            }
          }
          .title-vid {
            font-size: 26px;
            white-space: nowrap;
            color: white;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .secondary-text-vid {
            display: flex;
            justify-content: space-between;
            color: #ffffff;
            font-size: 16px;
            font-family: var(--primary-font);

            @media screen and (max-width: 600px) {
              font-size: 13px;
            }
          }
          .text-vid-left {
            display: flex;
            gap: 10px;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px
          }
          .text-vid-right {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px
          }
          .carousel-icon {
            margin: 0 5px;
            margin-right: 2.5px;
            height: 100%;
            fill: #ffffff;
            width: 20px;
          }
          .stack-vid-icon {
            display: flex;
            align-items: center;
          }
        `}</style>
      </>
    );
  };

  const isEven = (number: number) => {
    return (number % 2) == 1;
  };

  const getAmount = (amount: number) => {
    if (amount == 1) {
      return 1;
    }
    else { 
      if (amount >= 5){
        return 5
      }
      else {
        if (isEven(amount)){
          return amount;
        }
        else{ return amount - 1}
      }
    }
  };

  return (
    <React.Fragment>
      <div className="swiper-container mySwiper">
        {videos && videos.length > 0 && (
          <ResponsiveContainer
            carouselRef={ref}
            render={(parentWidth, carouselRef) => {
              const maxVisibleSlide = getAmount(videos.length);
              let currentVisibleSlide = getAmount(maxVisibleSlide);
              let slideWidth = 530;
              if (parentWidth <= 600) slideWidth = 300;

              return (
                <StackedCarousel
                  ref={carouselRef}
                  data={videos}
                  carouselWidth={parentWidth}
                  slideWidth={slideWidth}
                  slideComponent={Slide}
                  maxVisibleSlide={maxVisibleSlide}
                  currentVisibleSlide={currentVisibleSlide}
                  useGrabCursor={true}
                />
              );
            }}
          />
        )}

        {videos && videos.length > 1 && (
          <>
            <div
              className="slider-btn left"
              onClick={() => ref.current.goBack()}
              key={'left'}
            >
              <ArrowBackIcon />
            </div>

            <div
              className="slider-btn right"
              onClick={() => ref.current.goNext()}
              key={'right'}
            >
              <ArrowForwardIcon />
            </div>
          </>
        )}

        <style jsx global>{globalStyles}</style>
      </div>
    </React.Fragment>
  );
};
