import React, { useEffect, useState, useMemo } from "react";
import { CarouselComponent } from "./carousel/carousel.component";
import { UserBoxComponent } from "./userbox/userbox.component";
import { videoService } from "@services/video";
import Ads from "@components/ads/ads.component";
import { channelService } from "@services/channel";
import _get from "lodash/get";

interface IProps {
  channel?: any;
  id?: string;
  user?: any;
}

export const Banner: React.FC<IProps> = ({ channel }) => {
  const [videos, setVideos] = useState<any[]>([]);
  async function getVideos() {
    try {
      let response = [];
      if (_get(channel, "displayName", false)) {
        response = await channelService.getChannelVideos(
          channel._id,
          1
        );
        if (response.data === ''){
          response = await channelService.getChannelVideos(
            channel.id,
            1
          );
        }
      } else {
        response = await videoService.HomeDiscover();
      }
      //@ts-ignore
      setVideos(response.data.videos.sort(() => Math.random() - 0.5));
    } catch (err: any) {
      console.error("Banner: ", err.message);
    }
  }

  useEffect(() => {
    getVideos();
    // eslint-disable-next-line
  }, []);

  return useMemo(() => {
    return (
      <div className="at-container-pandding">
        <div className={`at-banner-area ${channel && "two-cols"}`}>
          {/* {channel && <UserBoxComponent channel={channel} />} */}
          <div className="at-bannervideobox">
            <CarouselComponent videos={videos} />
          </div>
          <Ads type="banner" />
          <style jsx global>{`
            .at-container-pandding {
              padding: 0 15px;
            }

            .at-banner .at-banner-area {
              display: flex;
              flex-direction: row;
              gap: 25px;
              padding: 0;
            }

            .at-bannervideobox {
              position: relative;
              display: flex;
              max-width: 100%;
              flex: 1;
            }

            .at-infobtn {
              background-color: white !important;
              border-radius: 8px !important;

              i {
                color: #fcd1f4 !important;
              }

              span {
                font-weight: 300;
              }
            }

            .at-addarea {
              .dvrzn {
                text-align: center;
                align-self: normal;
                background-color: white;
                color: #62a5d7;
                font-weight: 300;
                color: #62a5d7;
                border-radius: 8px;
                height: 300px;

                p {
                  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
                    "Segoe UI Emoji", "Segoe UI Symbol";
                  font-size: 16px;
                  font-weight: 200;
                  color: #6e6e6e;
                }
              }
            }
          `}</style>
        </div>
      </div>
    );
  }, [channel, videos]);
};
