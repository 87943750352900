
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Banner } from "@components/banner/banner.component";
import LeftMenuLayout from "@layouts/left-menu.layout";
import useTranslation from "next-translate/useTranslation";
import VideoList from "@components/video-list/video-list.component";
import { videoService } from "@services/video";
import { usePagination } from "@hooks/usePagination";
import Ads from "@components/ads/ads.component";
import { EVideoFilters } from "@enums/videoFilter.enum";
import {useEffect, useRef, useState} from "react";
/* import AppModal from "@components/modal-app/app.modal"; */
import useLocalStorage from "@hooks/useLocalStorage";
import { authService } from "@services/auth/index";
import { useAuth } from "@hooks/useAuth";
import _get from "lodash/get";
import {streamService} from "@services/stream";
import socketIOClient from "socket.io-client";
import Config from "@config/index";
import {usePickerState} from "@material-ui/pickers";

function Home() {
  const { t } = useTranslation("common");
  const [filter, setFilter] = useState<string>(EVideoFilters.TRENDING);
  const [userId, setUserId] = useState<string>('');
  const [categories, setCategories] = useState<string[]>([])
  const [user, setUser] = useLocalStorage<any>('user', null);
  const [streamViews, setStreamViews] = useState<any>({});
  const [streamDuration, setStreamDuration] = useState<any>({});

  const ws = useRef<any>(null);
  const SOCKET_SERVER_URL = Config.WS_URL
  const { logout } = useAuth()

  const [videos, { loading, nextPage, error, next, reload }] = usePagination(
    videoService.findAllWithStreams,
    "videos",
      {filter, categories, userId},
    {
      emptyMessage: t("messages.search.noVideos"),
      searchService: videoService.browse,
    }
  );

  const getUser = async () => {
  
    if(user){
      
      try {
        const { data }: any = await authService.getCurrentUser()

        if(data._id){
          data.id = data._id
        }

        if(data.id){
          data._id = data.id
        }
  
        setUser(data)

      } catch (err) {
  
        if(_get(err, "response.data.statusCode") === 401){
          logout()
        }
  
      }

    }

  }

  useEffect( () => {
    getUser()
  }, [])

  const updateStreamData = async (updsd: any) => {
    console.log("u")
    console.log("u")
    console.log("u")
    console.log(updsd)
    setStreamDuration(updsd.times)
    setStreamViews(updsd.views)
  }


  useEffect(() => {
    if (!ws.current) {
        ws.current = socketIOClient(SOCKET_SERVER_URL, {});
        ws.current.emit('event_join', {room: "main"});
        ws.current.on("vat", (mg: any) => updateStreamData(mg));
        // ws.current.on(GET_CHAT_MESSAGE_EVENT, addMessage);
        const wsCurrent = ws.current;
        return () => {
          wsCurrent.close();
        }
    }
  }, [videos]);

  return (
    <>

      {/* <AppModal /> */}

      <div className="stardeos">

        <LeftMenuLayout
          title={t("home.seo.title")}
          description={t("home.seo.description")}
          path={''}
          filter={filter}
          setFilter={setFilter}
          userId={userId}
          setUserId={setUserId}
          categories={categories}
          setCategories={setCategories}
          reload={reload}
        >
          {typeof window !== "undefined" && (
            <main className="at-main">
              <div className="at-banner">
                <Banner />
              </div>
              <Ads type="horizontal" />
              <VideoList
                videos={videos}
                next={next}
                loading={loading}
                error={error}
                nextPage={nextPage}
                streamDuration={streamDuration}
                streamViews={streamViews}
              />
            </main>
          )}

          <style jsx global>{`
            body {
              background-color: #0e1121;
            }
          
            .stardeos {

              position: relative;

            }

            .at-banner {

              @media screen and (max-width: 1000px){

                margin-bottom: 0px;

              }

            }

          `}</style>

        </LeftMenuLayout>

      </div>
    

    </>
  );
}

export default Home;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  