/* eslint-disable @next/next/no-css-tags */
import Head from "next/head";
import Link from "next/link";
import React, {useMemo, useState} from "react";
import {RecommendedComponent} from "@components/banner/recommended/recommended.component";
import {SubscriptionComponent} from "@components/banner/subscription/subscription.component";
import {TrendingComponent} from "@components/banner/trending/trending.component";
import {LatestComponent} from "@components/banner/latest/latest.component";                                                         
import { ListComponent } from "@components/banner/lists/list.component";
import { PaidSubscriptionComponent } from "@components/banner/paidsubscription/paidsubscription.component";
import {NavigationBar} from "@components/nav/nav.component";
import cx from "classnames";
import styles from "./left-menu.layout.module.scss";
import {useAuth} from "@hooks/useAuth";
import {EVideoFilters} from "@enums/videoFilter.enum";
import StardeosAd from "@components/stardeos-ad/stardeos-ad.component";

interface ILeftMenuLayout {
  children?: any;
  title?: string;
  description?: string;
  path?: string;
  styleClass?: string;
  filter: string;
  setFilter: Function;
  userId: string;
  setUserId: Function;
  categories: string[];
  setCategories: Function;
  reload: any;
}

const LeftMenuLayout: React.FC<ILeftMenuLayout> = ({
                                                     children,
                                                     title,
                                                     description,
                                                     path,
                                                     styleClass,
                                                     filter,
                                                     setFilter,
                                                     userId,
                                                     setUserId,
                                                     categories,
                                                     setCategories,
                                                     reload
                                                   }) => {
  const {user} = useAuth();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isDarked, setIsDarked] = useState<boolean>(true);

  const toggleCollapse = () => {
    setIsCollapsed(prevState => !prevState);
  };

  const toggleDropdown = (newValue: boolean) => {
    setIsDarked(newValue);
  };
  
  return useMemo(
    () => (
      <div className={styleClass}>
        <Head>
          <meta name="twitter:title" content={title}/>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <link rel="stylesheet" href="/assets/css/icomoon.css"/>
          <meta name="theme-color" content="#000000"/>
          <link rel="icon" href="/favicon.ico"/>
          <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
        </Head>
        <div className={styles["at-wrapper-one"]}>
          <header className="at-header at-header-one">
            <NavigationBar toggleCollapse={ toggleCollapse } toggleDropDown={ toggleDropdown }/>
          </header>
          <div className={isDarked ? "stbxcnt" : "stbxcnt darked"}>
            {typeof window !== "undefined" && (
              <aside className={isCollapsed ? "sdbr" : "collapsed sdbr"}>
                <div className={styles["principal-navigation-one"]}>
                  <TrendingComponent
                    active={filter === EVideoFilters.TRENDING}
                    setActive={setFilter}
                    setUserId={setUserId}
                    setCategories={setCategories}
                    reload={reload}
                  />
                  <LatestComponent
                    active={filter === EVideoFilters.LATEST}
                    setActive={setFilter}
                    setUserId={setUserId}
                    setCategories={setCategories}
                    reload={reload}
                  />
                
                  {user && (
                    <>
                      <SubscriptionComponent
                        active={filter === EVideoFilters.SUBSCRIPTIONS}
                        setActive={setFilter}
                        userId={user._id}
                        setUserId={setUserId}
                        setCategories={setCategories}
                        reload={reload}
                      />
                      <RecommendedComponent
                        active={filter === EVideoFilters.RECOMMENDATIONS}
                        setActive={setFilter}
                        setUserId={setUserId}
                        categories={user.categories}
                        setCategories={setCategories}
                        reload={reload}
                      />
                      {/*
                      <PaidSubscriptionComponent
                        active={filter === EVideoFilters.PAIDSUBSCRIPTIONS}
                        setActive={setFilter}
                        // userId={user._id}
                        setUserId={setUserId}
                        setCategories={setCategories}
                        reload={reload}
                      />
                      <ListComponent
                        active={filter === EVideoFilters.LISTS}
                        setActive={setFilter}
                        // userId={user._id}
                        setUserId={setUserId}
                        setCategories={setCategories}
                        reload={reload}
                      />
                        */}
                    </>
                  )}
                </div>
                <div className={styles["secondary-navigation-one"]} style={{position: "relative", zIndex: 999, marginBottom: "20px"}}></div>
                <div
                  className={cx(styles["adsense-one"], styles["ad200-200-one"])}
                >
                  <div className={styles["adsense-container-one"]}>
                    <div
                      style={{width: 200, height: 400}}
                    >

                      <StardeosAd
                        adType="left"
                        position="initial"
                        displayText={false}
                      />
                    </div>
                  </div>
                  <div className={styles["stardust-ads-container-one"]}
                       onClick={() => window.location.href = 'http://ads.stardeos.com/'}>

                    <div style={{fontSize: 14, fontWeight: 700}}>
                      ¡Anuncia <b>LO QUE QUIERAS</b>!
                      <br/>A partir de 5€-10,000 visitas.
                      <br/>¡Precios locos de salida!
                      <br/>👇 <b>CLIC AQUÍ</b> 👇
                      <br/><u><b>http://ads.stardeos.com/</b></u>
                    </div>
                  </div>
                </div>
                <div className={styles["secondary-navigation-one"]} style={{position: "relative", zIndex: 999}}>
                  <ul className="sdbr-mn list-unstyled">
                    <li>
                      <Link href={"/trabaja-con-nosotros"}>
                        <a className="dropdown-item">Trabaja con nosotros</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/terms-and-conditions"}>
                        <a className="dropdown-item">Términos y condiciones</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/legal-notice"}>
                        <a className="dropdown-item">Aviso legal y DMCA</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/cookies-policy"}>
                        <a className="dropdown-item">Política de cookies</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"https://discord.gg/JXYpqU5qgw"}>
                        <a className="dropdown-item">Soporte</a>
                      </Link>
                    </li>
                    <li>
                      <Link href={"/"}>
                        <a className="dropdown-item">© Stardeos</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            )}
            {children}
          </div>
        </div>
        <style jsx>{`

          .sdbr {
            ::-webkit-scrollbar {
              width: 3px; /* Set the width of the scrollbar */        
              height: 3px;      
            }
            
            ::-webkit-scrollbar-track {
              background-color: transparent; /* Set the background color of the scrollbar track */              
            }
            
            ::-webkit-scrollbar-thumb {
              background-color: #FFFFFF55; /* Set the color of the scrollbar thumb */
              border-radius: 5px; /* Set the border-radius of the scrollbar thumb */
              
            }
            
            ::-webkit-scrollbar-thumb:hover {
              background-color: #E7F3F388; /* Set the color of the scrollbar thumb on hover */
            }

            -webkit-transition: all 1s ease-in-out;
            transition: all 0.5s ease-in-out;
            margin-left: 0;
            overflow-y: scroll;
          }
          
          .sdbr.collapsed {
            margin-left: -220px;
          }

          .stbxcnt {
            -webkit-transition: all 1s ease-in-out;
            transition: all 0.5s ease-in-out;
          }
          
          .stbxcnt.darked {
            opacity: 0.3;
          }
        `}</style>
      </div>
    ),
    [styleClass, title, description, filter, setFilter, setUserId, setCategories, reload, user, children, isCollapsed, isDarked]
  );
};

export default LeftMenuLayout;
